import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/Vault.svg";
import Tilt from "react-parallax-tilt";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row style={{flexDirection: 'row-reverse'}}>
          <Col md={12} lg xxl={8} sm={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              A LOOK <span className="purple"> INSIDE </span> THE VAULT
            </h1>
            <p className="home-about-body">
              Our community supports a wide range of learners from{" "}
              <i>
                <b className="purple"> ages 7 - 18. </b>
              </i>
              We set the path from start to finish preparing our students for
              success in the field of their choosing.
              <br />
              <br />
              Here classes are divided by age, then size for a
              <i>
                <b className="purple"> personalized learning experience.</b>
              </i>{" "}
              Everyone here does not share the same learning style, so we assess our
              students' wants and needs to maximize
              <i>
                <b className="purple">&nbsp;engagement</b>
              </i>
              <i>
                <b className="purple"></b>
              </i>
              &nbsp; and quality of knowledge.&nbsp; We constantly promote
              <i>
                <b className="purple">&nbsp; creativity and customization</b>
              </i>{" "}
              &nbsp;within our classes. The majority of our courses require{" "}
              <i>
                <b className="purple">math and reading</b>
              </i>
              &nbsp; skills. In addition to special topics like <i>
                <b className="purple">game design </b>
              </i>{" "}, our
              students learn essential fundamental core subjects.
              <br />
              <br />
              The highly sought-after &nbsp;
              <i>
                <b className="purple">
                  Coding with Roblox and Minecraft &nbsp;
                </b>
              </i>
              courses take a deep dive into the{" "}
              <i>
                <b className="purple"> coding language </b>
              </i>
              and{" "}
              <i>
                <b className="purple">game design </b>
              </i>{" "}
              behind these projects. The spots for these courses tend to fill
              quickly, due to their popularity. We also have courses for kids
              beginning coding such as
              <i>
                <b className="purple"> Scratch or Python. </b>
              </i>{" "}
              &nbsp; Please check out our prfmaxovided courses by visiting the
              courses tab. &nbsp;
              <i>
                <b className="purple">Contact us </b>&nbsp;
              </i>
              or
              <i>
                <b className="purple"> Navigate to Courses</b>
              </i>{" "}
              for more information
              <br />
              <br />
              We are primarily a virtual environment teaching through &nbsp;
              <b className="purple">Zoom.</b> We also use this plotform for our{" "}
              <b className="purple"> 1on1 </b> tutoring sessions. <br />
              <i>
                <b className="purple"> </b>
              </i>
            </p>
          </Col>
          <Col md lg xl xxl={4} className="myAvatar">
            <Tilt>
              <div>
              <img src={myImg} className="img-fluid" alt="avatar" />
              </div>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONTACT US</h1>
            <p>
              Feel free to <a className="purple" href="mailto:online@codevault.club?&subject=Inquiry&body=Hello, I am interested in more information about this platform.">inquire</a>
            </p>
           
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
