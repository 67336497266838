import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../assets/Vault.svg";
import Particle from "../Particle";
import TypeWriterEffect from "react-typewriter-effect";
import Home2 from "./Home2";



function HomePage() {
  return (
    <section style={{ width: "100%" }}>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row className='home-header' style={{alignItems: 'center', verticalAlign: 'center'}}>
            <Col md={7} lg={7} xxl={7} sm={12} className="home-title">
              <h1 className="welcome">
                Welcome&nbsp;to

              </h1>

              <h1 className="companyName">

                <strong className="main-name"> CODE&nbsp;VAULT</strong>
              </h1>


              <div className='home-typer'>
                <TypeWriterEffect

                  startDelay={1000}
                  cursorColor="#3F3D56"
                  multiText={[
                    "Web Development",
                    "Graphic  Design",
                    "Game     Development",
                    "Computer Science",
                  ]}
                  multiTextDelay={1000}
                  typeSpeed={150}
                  multiTextLoop={true}

                />
              </div>



            </Col>

            <Col md={5} lg={5} xxl={5} sm={12} className="home-img">
              <img
                src={Logo}
                alt="home pic"
              />
            </Col>
          </Row>
        </Container>
        <Home2 />
      </Container>

    </section>
  );
}
export default HomePage;
