import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Creation from "../../assets/Creation.svg";
import Animation from "../../assets/Animation.svg";
import Javascript from "../../assets/Javascript.svg";
import python from "../../assets/python.svg";
import Minecraft from "../../assets/Minecraft.svg";
import nft from "../../assets/nft.svg";
import roblox from "../../assets/roblox.svg";
import WebDesign from "../../assets/WebDesign.svg";
// import CompSci from "../../assets/CompSci.svg";
// import Math from "../../assets/Math.svg";












function Courses() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        <strong className="purple">Course </strong> selection
        </h1>
        <p style={{ color: "white" }}>
          Here are the practical hands on courses we provide
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} xl={4} xxl={3}  className="project-card">
            <ProjectCard
              imgPath={Creation}
              isBlog={false}
              title="2D & 3D Character Creation"
              description="An introduction to 2D and 3D design concepts and terminology. Practical experience in the design and evaluation of 2D/3D still graphics. Students gain practical experience by storyboarding, designing, and rendering 2D/3D graphic objects. Training in industry standard 3D graphics programs guided by principles of design."
              link=""
            />
          </Col>

          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={Animation}
              isBlog={false}
              title="Animation"
              description=""
              link=""
            />
          </Col>

          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={Javascript}
              isBlog={false}
              title="Javascript"
              description=""
              link=""
            />
          </Col>
          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={Minecraft}
              isBlog={false}
              title="Minecraft"
              description=""
              link=""
            />
          </Col>
          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={nft}
              isBlog={false}
              title="NFT Collection"
              description=""
              link=""
            />
          </Col>

          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={python}
              isBlog={false}
              title="Python"
              description=""
              link=""
            />
          </Col>
          
          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={roblox}
              isBlog={false}
              title="Roblox"
              description=""
              link=""
            />
          </Col>

          <Col md={6} lg={4} xl={4} xxl={3} className="project-card">
            <ProjectCard
              imgPath={WebDesign}
              isBlog={false}
              title="Web Design"
              description=""
              link=""
            />
          </Col>



          





        </Row>
      </Container>
    </Container>
  );
}

export default Courses;
