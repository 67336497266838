import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiNetlify,
  SiHeroku,
  SiReplit,
  SiMinecraft,
  SiRoblox,
  SiAdobe,
  SiBlender,
  SiDiscord
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", alignItems:'center' }}>
      <Col xs={4} md={2} className="tech-icons minecraft">
        <SiMinecraft style={{transform: "scale(1.5)"}} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRoblox />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNetlify />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiReplit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobe />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBlender />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDiscord />
      </Col>
    </Row>
  );
}

export default Toolstack;
