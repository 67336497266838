import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
// import Aboutcard from "./AboutCard";
import vault from "../../assets/Vault.svg";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container className="about-content">
        <Row style={{justifyContent: 'center'}}>
          <Col
            md={7}
            className='about-header'
          >

            <h1 className='about-title'>
              What is </h1>

              <h1 className="companyName">

<strong className="main-name"> CODE&nbsp;VAULT</strong>
</h1>
            <h1>
Currently In Development ... </h1>
            {/* <Aboutcard /> */}
          </Col>
          <Col
             lg xl md={5}
            className="about-img"
          >
            <img src={vault} alt="about" />
          </Col>
        </Row>

        <h1 className="project-heading">
          Supported <strong className="purple"> Languages </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> Used
        </h1>
        <Toolstack />

      </Container>
    </Container>
  );
}

export default About;
