import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";


import {
  SiNextdotjs,
  SiLua,
  SiUnity,
  SiJava,
  SiMysql,
  SiScratch,
  SiHtml5,
  SiCsswizardry

} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", alignItems:'center' }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiLua />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <div >
        <SiJava />
        </div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs style={{transform: "scale(1.25)"}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact style={{transform: "scale(1.25)"}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsswizardry />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiUnity />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython style={{transform: "scale(1.25)"}}/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql style={{transform: "scale(1.25)"}} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit style={{transform: "scale(1.25)"}} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiScratch />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHtml5 />
      </Col>
      
    </Row>
  );
}

export default Techstack;
